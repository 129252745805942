/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 *
 * ======================================================================== */

window.$ = window.jQuery;

// Define Methods/Functions outside the router for reusability
var Broadcaster = require('./modules/broadcaster.js'),
	Scroller = require('./modules/scroller.js'),
	Sliders = require('./modules/Sliders.js'),
	MobileNav = require('./modules/mobileNav.js'),
	Debounce = require('./modules/debounce.js'),
	Collapser = require('./modules/collapser.js'),
	Macy = require('./modules/macy.js'),
	StoreMap = require('./modules/store-map.js'),
	Jarallax = require('jarallax/dist/jarallax'),
	// Popups                  = require('./modules/popups.js'),
	ejs = require('ejs'),
	ImagesLoaded = require('./modules/imagesLoaded.js');

let MappedinWeb = require("./modules/mappedin-web-v2");

/*
 * Use this variable to set up the common and page specific functions. If you
 * rename this variable, you will also need to rename the namespace below.
 */
var Router = {
	// All pages
	common: {

		init: function () {

			/*
			 * Javascript to be fired on every page
			 * These can be deleted or refactored to the page where it is used
			 */

			var lastScrollTop = 0;

			if (!localStorage.getItem('acceptedCookies')) {
				$('.cookies-message').removeClass('cookies-message--hidden');
			}

			$('.cookies-message .btn, .cookies-message__close').on('click', function (e) {
				e.preventDefault();

				$('.cookies-message').addClass('cookies-message--hidden');
				localStorage.setItem('acceptedCookies', 'true');
			})

			$('.site-header__hours-dropdown').on('click', function () {
				$(this).toggleClass('active');
			})

			$(document).on('scroll', function (e) {
				var scrollTop = $(this).scrollTop();

				if (scrollTop > lastScrollTop){
					if (scrollTop > $('.site-header').height()) {
						$('.site-header').addClass('site-header--fixed site-header--hide');

						setTimeout(function () {
							$('.site-header').addClass('animate');
						});
					}
				} else {
					if (scrollTop <= 0) {
						$('.site-header').removeClass('site-header--fixed site-header--hide');

						setTimeout(function () {
							$('.site-header').removeClass('animate');
						});
					} else if (scrollTop > $('.site-header').height()) {
						$('.site-header').addClass('site-header--fixed');
						$('.site-header').removeClass('site-header--hide');
					}
				}

				lastScrollTop = scrollTop;
			});

			// Initialize common controllers
			Sliders.init('.js-slider');
			MobileNav.init('.js-navigation-toggle');
			ImagesLoaded.init('.js-loaded');
			Collapser.init('.js-collapser-toggle');
			// Popups.init('.js-popup');

			// $(window).on('load', function () {
				Macy.init('#offers-listing-container', 2, {
					820: 1
				});

				$('.hero__bg').jarallax({
					speed: 0.3
				});

				$(window).on('scroll', function() {
					checkHeroBG()
				});

				var checkHeroBG = function () {
					var scrollTop = $(window).scrollTop();
					var height = $(window).height();

					$('.hero__bg div').css({
						'opacity': ((height - scrollTop) / height)
					});
				}

				checkHeroBG()
			// })

			$('.menu-item-search a, .btn-search').on('click', function (e) {
				e.preventDefault();
				$('body').toggleClass('show-search-modal');

				setTimeout(function() {
					$('.search-modal__form input').focus();
				}, 500);
			});

			$('.search-modal__close').on('click', function (e) {
				e.preventDefault();
				$('body').removeClass('show-search-modal');
			})

			$(document).keyup(function(e) {
				if (e.keyCode == 27) {
					$('body').removeClass('show-search-modal');
				}
			});

			var today = new Date().getDay() == 0 ? 7 : new Date().getDay();

			$('.hours-listing').each(function () {
				$(this).find('.one-third').removeClass('is-current-day').eq(today - 1).addClass('is-current-day');
				$(this).find('.two-thirds').removeClass('is-current-day').eq(today - 1).addClass('is-current-day');
			})

			$('.site-header__datetime').text($('.site-header .text--right.is-current-day').text());

			// hovering over newsletter icon or title activates hover for CTA
			$('.js-trigger-hover').hover(function() {
				$('.js-hovered').toggleClass('active');
			});
		},
		// Finalize will get fired after all js in common.init and page specific js
		finalize: function () {
			Scroller.init();
		}
	},

	home: {
		init: function () {

			$.fn.shuffle = function() {
				var allElems = this.get(),
					getRandom = function(max) {
						return Math.floor(Math.random() * max);
					},
					shuffled = $.map(allElems, function(){
						var random = getRandom(allElems.length),
							randEl = $(allElems[random]).clone(true)[0];
						allElems.splice(random, 1);
						return randEl;
					});

				this.each(function(i){
					$(this).replaceWith($(shuffled[i]));
				});

				return $(shuffled);
			};

			$('.heading__alternating-content span').shuffle();
			$('.heading__alternating-content span').eq(0).addClass('active');

			setInterval(function () {
				$('.heading__alternating-content').addClass('hidden');

				setTimeout(function () {
					var next = $('.heading__alternating-content .active').removeClass('active').next();

					if (next.length) {
						next.addClass('active');
					} else {
						$('.heading__alternating-content span').eq(0).addClass('active')
					}

					$('.heading__alternating-content').removeClass('hidden');
				}, 1000);
			}, 3000)

			Macy.init('#instagram-feed-container', 4, {
				1020: 2
			});

			var ScrollDown = require('./modules/scrollDown.js');
			ScrollDown.init('#scroll-to-content', '#page-masthead', true);

			var Swiper = require('swiper');

			var swiper = new Swiper('.swiper-container', {
				direction: 'horizontal',
				loop: false,
				centeredSlides: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				slidesPerView: 'auto',
				pagination: '.swiper-counter',
				paginationType: 'fraction',
				speed: 1000,
				autoplay: 2500
			})
		}
	},

	single: {
		init: function () {

			var SharePost = require('./modules/sharePost');
			SharePost.init();
		}
	},

	template_browse: {
		init: function () {

			$('.alphabet-listing-mobile').on('change', function () {
				var selectedLetter = $(this).val();
				scrollToLetter(selectedLetter);
			});

			$('.alphabet-listing a').on('click', function (e) {
				e.preventDefault();
				console.log('clicked');

				var selectedLetter = $(this).text();
				scrollToLetter(selectedLetter);
			});

			$(window).on('scroll', function () {
				if ($(this).scrollTop() > $(this).height()) {
					$('.btn-scroll-top-wrapper').addClass('active')
				} else {
					$('.btn-scroll-top-wrapper').removeClass('active')
				}
			});

			$('.btn-scroll-top').on('click', function () {

				$('body,html').animate({
					scrollTop: 0
				});
			});

			var scrollToLetter = function (selectedLetter) {
				$('.accordion__icon span').each(function () {
					if ($(this).text().toLowerCase() == selectedLetter.toLowerCase()) {
						var scrollTopPosition = $(this).parent().offset().top;

						$('body,html').animate({
							scrollTop: scrollTopPosition
						});
					}
				});
			}
		}
	},

	template_map: {
		init: function () {
			StoreMap.init();

			$('.map__zoom-in').on('click', function () {
				var currentZoom = mapView.controls.getZoom();
				mapView.controls.setZoom(currentZoom-=1000);
			})

			$('.map__zoom-out').on('click', function () {
				var currentZoom = mapView.controls.getZoom();
				mapView.controls.setZoom(currentZoom+=1000);
			})
		}
	},

	template_mappedin_web_v2: {
		init: function () {
			MappedinWeb.init({
				container: "map-wrapper__map-view"
			});
		}
	},

	archive: {
		init: function () {
			var hash = document.location.hash;

			$('.store-cards .grid__item').on('click', function () {
				document.location.href = $(this).find('a').prop('href');
			})

			$('.hero .btn--subcategory').on('click', function (e) {
				var $this = $(this);

				setTimeout(function () {
					hash = document.location.hash;
					$('.store-cards').addClass('hide');

					setTimeout(function() {
						$this.addClass('active').siblings().removeClass('active');

						$('.store-cards .grid__item').each(function () {

							if ($(this).data('cat').replace(/ /g, '-').toLowerCase().indexOf(hash.replace('#/', '-')) > -1) {
								$(this).show()
							} else {
								$(this).hide()
							}
						})

						$('.store-cards').removeClass('hide');
					}, 500);
				})
			})

			if (hash) {
				if ($('.hero .btn--subcategory').length > 0) {
					$('.hero .btn--subcategory').each(function () {
						var href = $(this).attr('href'),
							$this = $(this);

						setTimeout(function () {
							if (href == hash) {
								$this.click();
							}
						})
					})
				} else {
					$('.store-cards').removeClass('hide');
				}
			} else {
				$('.store-cards').removeClass('hide');
			}
		}
	},

	single_store: {
		init: function () {
			MappedinWeb.init({
				container: "single-store__map-view",
				width: "",
				height: "",
				position: ""
			});


			// StoreMap.init(true);
			//
			// $('.map__zoom-in').on('click', function () {
			// 	var currentZoom = mapView.controls.getZoom();
			// 	mapView.controls.setZoom(currentZoom-=1000);
			// })
			//
			// $('.map__zoom-out').on('click', function () {
			// 	var currentZoom = mapView.controls.getZoom();
			// 	mapView.controls.setZoom(currentZoom+=1000);
			// })
		}
	},

	template_offers_and_events: {
		init: function () {
			var html = '';

			var selectedFilter = $('.cards-overview__filters .btn.is-active').data('type');

			$('.cards-overview__filters .btn').on('click', function (e) {
				// e.preventDefault();

				$(this).addClass('is-active').siblings().removeClass('is-active');
				selectedFilter = $(this).data('type');

				render();
			});

			// var data = $('.cards-overview ').data('content');
			var data = PR.data;

			var render = function (firstPageLoad) {
				$('.cards-overview__results').addClass('hidden');
				var cardTemplate =
					'<% data.forEach(function(content){ %>' +
						'<% if (content.type == "' + selectedFilter + '"){ %>' +
							'<% if (content.type_name == "Featured Event"){ %>' +
								'<div class="grid__item one-whole">' +
									'<a href="<%= content.url %>" class="frame-card frame-card__featured">' +
										'<div class="grid grid--flex">' +
											'<div class="grid__item lg-one-whole one-half">' +
												'<div class="frame-card__featured-image" style="background-image: url(<%= content.image %>);"></div>' +
											'</div>' +
											'<div class="grid__item lg-one-whole one-half frame-card__featured-content">' +
												'<span><%= content.type_name %></span>' +
												'<h3><%- content.title %></h3>' +
												'<% if (content.location){ %>' +
                    								'<span class="frame-card__location"> <span class="icon-location"></span> <%= content.location %></span>' +
                    							'<% } %>' +
                    							'<% if (content.start == content.end){ %>' +
													'<span class="frame-card__date"> <span class="icon-calendar"></span> <%= content.end %>, <%= content.end_year %></span>' +
												'<% } else { %>' +
													'<span class="frame-card__date"> <span class="icon-calendar"></span> <%= content.start %>, <%= content.start_year %> - <%= content.end %>, <%= content.end_year %></span>' +
												'<% } %>' +
											'</div>' +
										'</div>' +
									'</a>' +
								'</div>' +
							'<% } else { %>' +
								'<div class="grid__item one-half lg-one-whole">' +
									'<a href="<%= content.url %>" class="frame-card">' +
										'<span><%= content.type_name %></span>' +
										'<h3><%= content.title %></h3>' +
										'<% if (content.location){ %>' +
											'<span class="frame-card__location"> <span class="icon-location"></span> <%= content.location %></span>' +
                    					'<% } %>' +
                    					'<% if (content.start == content.end){ %>' +
											'<span class="frame-card__date"> <span class="icon-calendar"></span> <%= content.end %>, <%= content.end_year %></span>' +
										'<% } else { %>' +
											'<span class="frame-card__date"> <span class="icon-calendar"></span> <%= content.start %>, <%= content.start_year %> - <%= content.end %>, <%= content.end_year %></span>' +
										'<% } %>' +
									'</a>' +
								'</div>' +
							'<% } %>' +
						'<% } %>' +
					'<% }); %>';

				var offerCardTemplate =
					'<% data.forEach(function(content){ %>' +
						'<% if (content.type == "' + selectedFilter + '"){ %>' +
							'<div class="grid__item one-half">' +
								'<a href="<%= content.url %>" class="offer-card">' +
									'<div class="offer-card__image-wrapper">' +
										'<img src="<%= content.image %>" alt="Offer title <%= content.title %>" class="offer-card__image">' +

										'<span class="offer-card__hover">' +
											'<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 225.1 224.8"><style>.st0{fill:#242360;}</style><path class="st0" d="M0 0v224.8h225.1V0H0zm215 215H9.9V9.8H215V215z"/><path class="st0" d="M48.6 103.7h7c21.2 0 42.3.1 63.5 0 13.7-.1 25.8-10.5 26.8-23.1h-9.5c-4.4 11-13 14-24.1 13.7-19.1-.4-38.3-.1-57.5-.1-1.9 0-3.9-.2-5.9-.3v-37-2.4h5.7c21 0 42-.1 63.1 0 5.2 0 9.9 2 13.4 5.8 2.4 2.5 4 5.7 5.9 8.4h9.2c-.6-5.9-3.1-10.1-6.3-14-6.6-8.1-15.8-10.2-25.5-10.2-22.9-.1-45.8-.1-68.6 0H39V159h9.6v-55.3z"/><path class="st0" d="M104.1 70.3c-2.9 0-5.9 1-8.7 1.9-6.9 2.3-17.1 13.2-15.9 21.2h9.2c4.6-10.8 12.9-14.2 23.8-14 18 .3 36 .1 53.9.1h9.4V119.3h-6.5c-19 0-38-.4-57 .1-11.2.3-19.5-3.2-24-13.9-.1-.3-.5-.4-.6-.5h-8.8c.7 10.1 8.9 19.9 19.1 22.4 3.7.9 7.6 1.1 11.4 1.2 9.2.2 18.5.1 27.7.1.1.4.2.7.3 1.1-20.8 17.9-41.7 35.7-62.5 53.6 7.2 1.5 13.8 2.6 19.2-3.5 3.5-3.9 8-7 12-10.5 15.8-13.6 31.5-27.1 46.9-40.3h23.7v54.7h9.2V70.2h-5.1c-25.6 0-51.1 0-76.7.1z"/></svg>' +
											'<span>See Details</span>' +
										'</span>' +
									'</div>' +

									'<div class="offer-card__content">' +
										'<div class="grid grid--flex">' +
											'<div class="grid__item one-third lg-one-whole offer-card__logo-wrapper">' +
												'<img src="<%= content.logo %>" alt="<%= content.location %> logo" class="offer-card__logo">' +
											'</div>' +
											'<div class="grid__item two-thirds lg-one-whole">' +
												'<p><%= content.title %></p>' +

												'<span class="offer-card__date">' +
													'<% if (content.start == content.end){ %>' +
													'<span class="icon-calendar"></span> <%= content.end %>, <%= content.end_year %>' +
													'<% } else { %>' +
													'<span class="icon-calendar"></span> <%= content.start %>, <%= content.start_year %> - <%= content.end %>, <%= content.end_year %>' +
													'<% } %>' +
												'</span>' +
											'</div>' +
										'</div>' +
									'</div>' +
								'</a>' +
							'</div>' +
						'<% } %>' +
					'<% }); %>';

				if ( selectedFilter === 'offer' ) {
					html = ejs.render(offerCardTemplate, { data: data });
				} else {
					html = ejs.render(cardTemplate, { data: data });
				}

				$('.cards-overview__blurb').removeClass('active');

				setTimeout(function() {
					$('.cards-overview__results').html(html);

					$('.cards-overview__blurb').hide();

					setTimeout(function() {
						$('.cards-overview__blurb[data-type="' + selectedFilter + '"]').show().addClass('active');

						if (selectedFilter === 'offer') {
							$('.cards-overview__results').addClass('offers-listing');

							window.macyInstance = Macy.init('#cards-overview__container', 2, {
								820: 1
							});
						} else {
							if (window.macyInstance) window.macyInstance.remove();
							$('.cards-overview__results').removeClass('offers-listing').attr('style', '');
						}

						// $('.cards-overview__results-wrapper').height($('.cards-overview__results').height());
						$('.cards-overview__results').removeClass('hidden');

						// if (!firstPageLoad) {
							// $('body,html').animate({
							// 	scrollTop: $('.cards-overview__content').offset().top - $('#site-header').height()
							// })
						// }
					}, 350);
				}, 350);
			}

			render(true);

			var hash = document.location.hash;
			$('.cards-overview__filters a').each(function () {
				if ($(this).attr('href') == hash) {
					$(this).click()
				}
			})
		}
	},

	template_location: {
		init: function () {
			var Swiper = require('swiper');

			var swiper = new Swiper('.swiper-container', {
				direction: 'horizontal',
				loop: true,
				pagination: '.swiper-pagination',
				paginationClickable: true,
				autoplay: 2500
			})

			$('.accordion__title').on('click', function () {
				$(this).parent().toggleClass('is-active');
			})
		}
	},

	template_directory: {
		init: function () {
			//this.getStoreInfo();
			var originalSearchPlaceholder = $('.directory-search__input').attr('placeholder');

			$(window).on('resize load', function () {
				$('.directory-search__input').attr('placeholder', $(this).width() < 400 ? 'Find Stores & More' : originalSearchPlaceholder);
			})

			$('.store-cards .grid__item').on('click', function () {
				document.location.href = $(this).find('a').prop('href');
			})

			$('.template-directory .hero form').on('submit', function (e) {
				e.preventDefault();
			})

			$('.template-directory .hero form .icon-times').on('click', function () {
				$('.directory-search__input').val('');

				$('.directory-search').removeClass('is-searching');
			});

			$('.directory-search__input').on('focus blur keyup', function (e) {
				if (e.type == 'keyup') {
					if ($('.directory-search__input').val().length >= 2) {
						getStores($('.directory-search__input').val());

						$('.directory-search').addClass('is-searching');
					} else {
						$('.directory-search').removeClass('is-searching');
						$('.directory-search__no-matches').removeClass('is-active');
					}
				} else {
					$('.directory-search').toggleClass('is-focused');
				}
			})

			function getStores(value) {
				var counter = 0;

				$('.directory-search__results .grid__item').each(function () {
					if ($(this).find('.directory-search__store-name').text().toLowerCase().indexOf(value.toLowerCase()) > -1) {
						$('.directory-search__no-matches').removeClass('is-active');

						if (counter < 3) {
							$(this).addClass('active');
							const img = this.querySelector('img');
							img.setAttribute('src', img.dataset.storeimg);
							$('.directory-search__results').removeClass('is-hiding-results');
						} else {
							$(this).removeClass('active');
							$('.directory-search__results').addClass('is-hiding-results');
						}

						counter++;
					} else {
						if (counter < 3) {
							if (counter == 0) {
								$('.directory-search__no-matches').addClass('is-active');
							}
							$('.directory-search__results').removeClass('is-hiding-results');
						}

						$(this).removeClass('active');
					}
				});
			}
		},
		getStoreInfo(){

			const locationLogos = document.querySelectorAll('.directory-search__store-logo');
			locationLogos.forEach(logo => {
				const locationID = logo.dataset.storeid;
				let store = store_locations.find(o => o.id === locationID);
				if(store.logo){
					logo.setAttribute('data-src', store.logo.medium);
				} else {
					console.warn("Logo not found: " + store);
				}
			})

		}
	},

	blog: {
		init: function(){

			// Category dropdown functionality
			var dropdown = document.getElementById("blog-categories");
			function onCatChange() {
				if ( dropdown.options[dropdown.selectedIndex].value > 0 ) {
					location.href = "/?cat="+dropdown.options[dropdown.selectedIndex].value;
				}else{
				    location.href = "/blog/";
                }
			}
			dropdown.onchange = onCatChange;
		}
    },

    category: {
		init: function(){

			// Category dropdown functionality
			var dropdown = document.getElementById("blog-categories");
			function onCatChange() {
				if ( dropdown.options[dropdown.selectedIndex].value > 0 ) {
					location.href = "/?cat="+dropdown.options[dropdown.selectedIndex].value;
				}else{
                    location.href = "/blog/";
                }
			}
			dropdown.onchange = onCatChange;
		}
	},

	template_content: {
		init: function () {
			var startHiding = false;

			$('.page-content .container--flush *').each(function () {
				if ($(this).html() == '<!--more-->') {
					startHiding = true;
					$(this).after('<div class="read-more-block"><button class="btn"><span>Read More</span></button></div>');
				} else if (startHiding) {
					$(this).hide();
				}
			})

			$('.accordion__title').on('click', function () {
				$(this).parent().toggleClass('is-active');
			})

			$('.page-content .read-more-block .btn').on('click', function () {
				$('.page-content .container--flush *').show();
				$(this).parent().hide();
			})
		}
	},

	template_contact: {
		init: function () {

			var Maps = require('./modules/maps.js'),
				FormFocus = require('./modules/formFocus.js');

			Maps.init('.js-map');
			FormFocus.init();
		}
	}
};

/*
 * The routing fires all common scripts, followed by the page specific scripts.
 * Add additional events for more control over timing e.g. a finalize event
*/
var UTIL = {

	fire: function (func, funcname, args) {
		var namespace = Router;
		funcname = (funcname === undefined) ? 'init' : funcname;
		if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function () {
		UTIL.fire('common');
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
			UTIL.fire(classnm);
		});

		UTIL.fire('common', 'finalize');
	}

};

$(document).ready(UTIL.loadEvents);
