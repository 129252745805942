

$.fn.extend({

    googleMap: function(){

        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        var el            = this,
            address       = this.data('address'),
            markerImgSrc  = this.data('marker'),
            geocoder      = new google.maps.Geocoder(), // Create an instance of the google maps geocoder
            map           = new google.maps.Map( this[0] , mapOptions ),
			mapOptions    = {
                disableDefaultUI : true,
                center           : { lat: 0, lng: 0}, // Make sure to set a good fallback for the center
                zoom             : this.data('zoom') ? this.data('zoom') : 14,
                draggable        : isMobile ? false : this.data('draggable'),
                scrollwheel      : this.data('scrollwheel')
            };

        this.getMarker = function(src) {

            var res    = null,
                marker = $(new Image());

            marker[0].src = src;

            marker
                .load(function(){

                    var w = marker[0].width,
                        h = marker[0].height;

                    res =  new google.maps.MarkerImage( src ,
                                new google.maps.Size(w, h), // size
                                new google.maps.Point(0, 0), // origin point
                                new google.maps.Point(w/2, h/2 )); // offset point

                    el.trigger('googleMarkerLoaded', res );

                })
                .error(function(){
                    el.trigger('googleMarkerLoaded', res );
                });


        };

        el.on('googleMarkerLoaded', function(e, mapMarker){

            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    //In this case it creates a marker, but you can get the lat and lng from the location.LatLng
                    map.setCenter(results[0].geometry.location);

                    var marker = new google.maps.Marker({
                        map: map,
                        icon: mapMarker ,
                        position: results[0].geometry.location
                    });
                }
            });

        });

        if (markerImgSrc) {
            this.getMarker(markerImgSrc);
        } else {
            el.trigger('googleMarkerLoaded');
        }
    }
});

var Maps = {

    init: function(className) {
        try {

            $(className).each(function(){
                $(this).googleMap();
            });
        }

        catch(e) {}
    }
}

module.exports = Maps;
