var StoreMap = {
	init: function (focusOnPolygonAfterLoad) {
		var mapList = document.getElementById("map-wrapper__list");
		var locations = undefined;

		var week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		var today = week[new Date().getDay()];

		var polygonedLocations = [];
		var venueOptions = {
			clientId: "eihaiph1jeekah1uj5eej0cae3eeV4Oo",
			clientSecret: "doefeeM6eiMiephu",
			perspective: "Website",
			things: {
				venue: ['slug', 'name'],
				locations: ['name', 'type', 'description', 'icon', 'logo', 'venue', 'active', 'address', 'amenity', 'categories', 'changeset', 'color', 'contact', 'denominations', 'description', 'descriptions', 'direction', 'directionsName', 'email', 'featured', 'hasContent', 'imported', 'lat', 'long', 'mapName', 'mobilefringe', 'nodes', 'phone', 'picture', 'pictures', 'searchableNames', 'showLabel', 'showLogo', 'social', 'tags', 'title', 'toMap', 'typeRank', 'visibilityScore', 'operationHours'],
				categories: ['name','value','venue','parents'],
				maps: ['name', 'elevation', 'shortName'],
				events: ['name', 'showDate', 'startDate' , 'endDate', 'type', 'venue', 'brand', 'description', 'featured', 'font', 'fontColor', 'headline', 'image', 'lead', 'location', 'logo', 'message', 'messageId', 'template'],
			},
			venue: "park-royal"
		};

		var mapviewOptions = {
			antialias: "AUTO",
			mode: Mappedin.modes.TEST,
			onFirstMapLoaded: function () {
				console.log("First map fully loaded. No more pop in.");
			},
			onDataLoaded: function() {
				console.log("3D data loaded, map usable. Could hide loading screen here, but things will be popping in. Now you can do things that interact with the 3D scene")
				onDataLoaded()
			}
		};

		var searchOptions = {
			key: "",
			secret: ""
		};

		var options = {
			mapview: mapviewOptions,
			venue: venueOptions,
			search: searchOptions
		};

		var element = document.getElementById( 'map-wrapper__map-view' );

		if (element) {
			Mappedin.initialize(options, element).then(function (data) {
				mapView = data.mapview
				venue = data.venue
				search = data.search
			},function (error) {
				window.alert("Mappedin " + error)
			})
		}

		var element = document.getElementById( 'single-store__map-view' );

		$('.map__close-store-info').on('click', function () {
			mapView.resetCamera()
			mapView.clearAllPolygonColors();

			$('.map__store-info').hide();
		})

		if (element) {
			Mappedin.initialize(options, element).then(function (data) {
				mapView = data.mapview
				venue = data.venue
				search = data.search

			},function (error) {
				window.alert("Mappedin " + error)
			})
		}

		function onDataLoaded() {
			if (focusOnPolygonAfterLoad) {
				var polygonId = $('.single-store-wrapper').data('store-id');

				setTimeout(function() {
					mapView.setMap($('.single-store-wrapper').data('store-map'), function () {
						console.log("Map changed to " + $('.single-store-wrapper').data('store-map'))

						mapView.clearAllPolygonColors()
						mapView.setPolygonColor(polygonId, 2368352, 16777215)

						mapView.focusOnPolygon(polygonId, true, 0, Mappedin.Easing.Quadratic.Out)
					})
				}, 1000);
			} else {
				if (document.location.hash) {
					var mapId = document.location.hash.split('/')[1]
					var polygonId = document.location.hash.split('/')[2]

					mapView.setMap(mapId, function () {
						mapView.setPolygonColor(polygonId, 2368352, 16777215)
						mapView.focusOnPolygon(polygonId, true, 0, Mappedin.Easing.Quadratic.Out)

						openStoreInfo(polygonId)
					})
				}

				mapView.controls.enableZoom = false;
				mapView.onPolygonClicked = onPolygonClicked
				locations = venue.locations;

				for (var j = 0, jLen = locations.length; j < jLen; ++j) {
					var location = locations[j];

					console.log('----------------')
					console.log(location)

					if (location.polygons.length > 0) {
						polygonedLocations.push(location)
					}

					var locationPolygons = location.polygons;

					for (var k = 0, kLen = locationPolygons.length; k < kLen; ++k) {
						var polygon = locationPolygons[k];

						console.log(location.name, polygon.id, polygon.map, mapView.currentMap)

						var options = {
							text: location.name,
							polygon: polygon,
							hoverLabelClass: 'map-store-label',
							canvasBounds: {
								align: 'center'
							},
							map: polygon.map
						}

						var a = mapView.labelPolygon(options);
						mapView.addInteractivePolygon(polygon.id)
					}
				}

				var maps = venue.maps;

				for (var m = 0, mLen = maps.length; m < mLen; ++m) {
					var map = maps[m];
					var mapId = map.id;
					var item = document.createElement("option")
					item.text = map.shortName
					item.value = map.id
					item.id = map.id
					if (mapId == mapView.currentMap) {
						item.selected = true
					}
					mapList.add(item)
				}

				// mapView.labelAllLocations();
			}
		}

		function onPolygonClicked (polygonId) {
			mapView.clearAllPolygonColors()
			mapView.setPolygonColor(polygonId, 2368352, 16777215)
			mapView.focusOnPolygon(polygonId, true)

			openStoreInfo(polygonId);

			return false
		}

		var stringToSlug = function (str) {
			str = str.replace(/^\s+|\s+$/g, '');
			str = str.toLowerCase();

			var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
			var to   = "aaaaeeeeiiiioooouuuunc------";

			for (var i=0, l=from.length ; i<l ; i++) {
				str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
			}

			str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

			return str;
		}

		function storeIsOpen(openTime, closeTime){
			var todaysDate = new Date().getMonth() + 1 + '/' + new Date().getDate() + '/' + new Date().getFullYear() + ' ';
			var nowTime = addZero(new Date().getHours()) + ':' + addZero(new Date().getMinutes())

			if ( (todaysDate + nowTime + ':00') >= (todaysDate + openTime + ':00') && (todaysDate + nowTime + ':00') < (todaysDate + closeTime + ':00') ) {
				return true
			}

			return false
		}

		function addZero(i) { if (i < 10) i = "0" + i; return i; }

		var hourRowTemplate = 	'<span class="map__store-hours-row">' +
									'<span class="map__store-opens"></span> - <span class="map__store-closes"></span> <span class="map__store-status-label"></span>' +
								'</span>';

		function openStoreInfo (polygonId) {
			$('.map__store-hours-wrapper .map__store-hours-row').remove()
			$('.map__store-logo').hide()
			$('.map__store-logo-wrapper').slideUp()
			var isOpen = false;

			locations.forEach(function (store) {

				store._data.polygons.forEach(function(storePolygon) {
					if (storePolygon.id === polygonId) {
						if( 'undefined' !== typeof store.name ) {
							$('.map__store-name').text(store.name)
						}
						if( 'undefined' !== typeof store.phone) {
							$('.map__store-phone').text(store.phone.number)
						}else{
							$('.map__store-phone').text('')
						}

						if (store.logo) {
							var image = new Image() ;
							image.src = store.logo.small;

							$('.map__store-logo').attr('src', store.logo.small)
							image.onload = function() {
								$('.map__store-logo-wrapper').stop(false, false).slideDown()
								$('.map__store-logo').fadeIn()
							};
						}

						$('.map__store-url').attr('href', '/store/' + stringToSlug(store.name))

						store.operationHours.forEach(function(time) {
							if (time.dayOfWeek.indexOf(today) > -1) {
								var $row = $(hourRowTemplate);

								if (!isOpen) {
									if (storeIsOpen(time.opens, time.closes)) {
										isOpen = true;
									}
								}

								$row.find('.map__store-opens').text(time.opens)
								$row.find('.map__store-closes').text(time.closes)

								$('.map__store-hours-group').append($row);

								if (isOpen) {
									$('.map__store-status-label').text('Open Now');
								} else {
									$('.map__store-status-label').text('Closed');
								}
							}
						})
					}
				});

				$('.map__store-info').show()
			});
		}

		function onNothingClicked() {
			console.log("onNothingClicked")
			mapView.clearAllPolygonColors()
		}

		function setMap(map) {
			mapList.selectedIndex = mapList.namedItem(map).index
			mapView.setMap(map)
		}

		function changeMap() {
			mapView.setMap(mapList.value, function () {
				console.log("Map changed to " + mapList.value)
			})
		}

		if (mapList) {
			mapList.addEventListener("change", changeMap)
		}
	}

}

module.exports = StoreMap;
