var Macy = require('macy');

var MacyGrid = {

	init: function (macySelector, columns, breakAt) {
		if (document.getElementById(macySelector.replace('#', ''))) {
			window.grid = Macy({
				container: macySelector,
				trueOrder: false,
				waitForImages: false,
				margin: 0,
				columns: columns,
				breakAt: breakAt
			});

			grid.runOnImageLoad(function (e) {
				$('.offer-card__image').each(function () {
					if ($(this).height() > $(this).width()) {
						$(this).parents('.offer-card').addClass('offer-card--portrait');
					}
				})

				grid.recalculate(true, true);
			}, true);

			$(window).on('load', function () {
				grid.recalculate(true, true);
			})
		}
	},
	remove: function () {
		grid.remove();
	}

}

module.exports = MacyGrid;
