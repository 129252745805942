var MappedinWeb = {
	defaultOptions: {
		clientID: "67448505f979bbf9ca44c23c",
		clientSecret: "zHZRUIWeCWLUltBHjBM8AO0R5WynjW2LB1uN9ZaAcrv73tDl",
		venueSlug: "park-royal",
		useDraftData: false,
		useEUServer: false,
		container: "map-wrapper__map-view", // Default container ID
		legal: {}, // Optional: e.g., {termsOfUse: "example.com/terms", privacyPolicy: "example.com/privacy"}
		language: "en", // Default is English
		width: "100%", // CSS units for map width
		height: "75svh", // CSS units for map height
		position: "relative"
	},

	options: {},

	init: function (userOptions) {

		// Merge user-provided options with default options
		this.options = Object.assign({}, this.defaultOptions, userOptions);

		// Ensure the required parameters are set
		if (!this.options.clientID || !this.options.clientSecret || !this.options.venueSlug) {
			console.error("MappedinWeb.init() failed: 'clientID', 'clientSecret', and 'venueSlug' are required.");
			return;
		}

		// Get the container element for the map
		var container = document.getElementById(this.options.container);
		if (!container) {
			console.error(
				`MappedinWeb.init() failed: Unable to find container element with ID '${this.options.container}'.`
			);
			return;
		}

		// Remove any existing map element before re-initializing
		var existingMapElement = document.getElementById("mappedin-map");
		if (existingMapElement) {
			existingMapElement.remove();
		}

		// Add a new map container inside the specified container
		var mapElement = document.createElement("div");
		mapElement.id = "mappedin-map";
		if (this.options.position) {
			mapElement.style.position = this.options.position; // Defaults to relative
		}
		if (this.options.width) {
			mapElement.style.width = this.options.width; // Set map width dynamically
		}
		if (this.options.height) {
			mapElement.style.height = this.options.height; // Set map height dynamically
		}
		container.appendChild(mapElement);

		// Inject the stylesheet into the document head
		// var stylesheet = document.querySelector("link[href='https://cdn.mappedin.com/web2/release/mappedin-web.css']");
		// if (!stylesheet) {
		// 	stylesheet = document.createElement("link");
		// 	stylesheet.rel = "stylesheet";
		// 	stylesheet.href = "https://cdn.mappedin.com/web2/release/mappedin-web.css";
		// 	document.head.appendChild(stylesheet);
		// }

		// Set up the global Mappedin configuration
		window.mappedin = {
			miKey: {
				id: this.options.clientID,
				key: this.options.clientSecret,
			},
			venue: this.options.venueSlug,
			useDraftData: this.options.useDraftData,
			useEUServer: this.options.useEUServer,
			language: this.options.language,
			legal: this.options.legal, // Optional: termsOfUse, privacyPolicy
		};

		// Add the Mappedin Web SDK script to the page if not already present
		// if (!document.getElementById("mappedin-web-script")) {
		// 	var script = document.createElement("script");
		// 	script.id = "mappedin-web-script";
		// 	script.type = "module";
		// 	script.src = "https://cdn.mappedin.com/web2/release/mappedin-web.js";
		// 	document.body.appendChild(script);
		// }

		// Inject the external stylesheet for MappedIn Web V2
		this.addStylesheet();

		// Inject the MappedIn Web V2 script dynamically
		this.addScript(() => {
			console.log("Mappedin Web V2 loaded and initialized.");
		});

		console.log("Mappedin Web initialized successfully.");
	},

	reset: function () {
		// Remove the global Mappedin script
		var scriptElement = document.getElementById("mappedin-web-script");
		if (scriptElement) scriptElement.remove();

		// Remove the map container div
		var mapElement = document.getElementById("mappedin-map");
		if (mapElement) mapElement.remove();

		// Clear the `window.mappedin` configuration
		delete window.mappedin;

		console.log("Mappedin Web reset.");
	},

	addStylesheet: function () {
		// Check if the stylesheet is already added
		const existingStylesheet = document.querySelector("link[href='https://cdn.mappedin.com/web2/release/mappedin-web.css']");
		if (!existingStylesheet) {
			const stylesheet = document.createElement("link");
			stylesheet.rel = "stylesheet";
			stylesheet.href = "https://cdn.mappedin.com/web2/release/mappedin-web.css";
			document.head.appendChild(stylesheet);
		}
	},

	addScript: function (callback) {
		// Check if the script is already added
		const existingScript = document.getElementById("mappedin-web-script");
		if (!existingScript) {
			const script = document.createElement("script");
			script.id = "mappedin-web-script";
			script.type = "module";
			script.src = "https://cdn.mappedin.com/web2/release/mappedin-web.js";

			// Attach a callback when the script loads successfully
			script.addEventListener("load", callback);

			// Append the script to the body
			document.body.appendChild(script);
		} else if (callback) {
			// If the script is already loaded, call the callback immediately
			callback();
		}
	},

};

module.exports = MappedinWeb;
