module.exports={
  "_args": [
    [
      "ejs@2.7.4",
      "/Users/alfredorodriguez/Documents/Sites/Local Sites/parkroyal/app/public/wp-content/themes/parkroyal"
    ]
  ],
  "_from": "ejs@2.7.4",
  "_id": "ejs@2.7.4",
  "_inBundle": false,
  "_integrity": "sha512-7vmuyh5+kuUyJKePhQfRQBhXV5Ce+RnaeeQArKu1EAMpL3WbgMt5WG6uQZpEVvYSSsxMXRKOewtDk9RaTKXRlA==",
  "_location": "/ejs",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "ejs@2.7.4",
    "name": "ejs",
    "escapedName": "ejs",
    "rawSpec": "2.7.4",
    "saveSpec": null,
    "fetchSpec": "2.7.4"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/ejs/-/ejs-2.7.4.tgz",
  "_spec": "2.7.4",
  "_where": "/Users/alfredorodriguez/Documents/Sites/Local Sites/parkroyal/app/public/wp-content/themes/parkroyal",
  "author": {
    "name": "Matthew Eernisse",
    "email": "mde@fleegix.org",
    "url": "http://fleegix.org"
  },
  "bugs": {
    "url": "https://github.com/mde/ejs/issues"
  },
  "dependencies": {},
  "description": "Embedded JavaScript templates",
  "devDependencies": {
    "browserify": "^13.1.1",
    "eslint": "^4.14.0",
    "git-directory-deploy": "^1.5.1",
    "jake": "^10.3.1",
    "jsdoc": "^3.4.0",
    "lru-cache": "^4.0.1",
    "mocha": "^5.0.5",
    "uglify-js": "^3.3.16"
  },
  "engines": {
    "node": ">=0.10.0"
  },
  "homepage": "https://github.com/mde/ejs",
  "keywords": [
    "template",
    "engine",
    "ejs"
  ],
  "license": "Apache-2.0",
  "main": "./lib/ejs.js",
  "name": "ejs",
  "repository": {
    "type": "git",
    "url": "git://github.com/mde/ejs.git"
  },
  "scripts": {
    "postinstall": "node ./postinstall.js",
    "test": "mocha"
  },
  "version": "2.7.4"
}
